<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="weather" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagCfg"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('cfg')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Zeit">
                        <Panel header="DST-Steuerung">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Automatische DST Steuerung (Achtung: bei Linux AUS lassen - OS behandelt TZ-Korrektur auch OHNE NTP)
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('clockAutoDst').value" /><span class="ml-2">{{ this.getDiagDataCfg('clockAutoDst').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="TIMEZONE-Steuerung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Automatische Zeitzonen-Erfassung (Achtung: in Linux mit timedatectl die richtige Timezone kontrollieren)
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('clockAutoTz').value" /><span class="ml-2">{{ this.getDiagDataCfg('clockAutoTz').label }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="clockOffsetTz" ref="clockOffsetTz" :min="0" :max="720"
                                            v-model="this.getDiagDataCfg('clockOffsetTz').value"
                                            :suffix="' ' + this.getDiagDataCfg('clockOffsetTz').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="clockOffsetTz" class="mr-2">{{ this.getDiagDataCfg('clockOffsetTz').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="GEO-Standort">
                        <Panel header="AUTO-Koordinaten">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                           Bei Aktivierung dieser Funktion wird nach dem Speichern die Wetterstation manuell nach dem aktuellen Standort gefragt und das Ergebnis in die Steuerung gespeichert.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('geoManualFetch').value" /><span class="ml-2">{{ this.getDiagDataCfg('geoManualFetch').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Wenn aktiviert, liefert die Wetterstation die aktuellen Standortdaten an die Steuerung. Ein Wert größer als 0 Tage aktiviert die automatische Standorterfassung.
                                        </InlineMessage>
                                        <span class="p-float-label">
                                            <InputNumber id="geoAutoFetch" ref="geoAutoFetch" :min="0" :max="3650"
                                                v-model="this.getDiagDataCfg('geoAutoFetch').value"
                                                :suffix="' ' + this.getDiagDataCfg('geoAutoFetch').unit"
                                                :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                                @focus="focusElement" class="p-inputtext-lg" />
                                            <label for="geoAutoFetch" class="mr-2">{{ this.getDiagDataCfg('geoAutoFetch').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Lokale Koordinaten (ohne Wetterstation)" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Wenn aktiviert, werden die Daten der Wetterstation ignoriert und stattdessen die unten eingetragenen GEO-Koordinaten verwendet.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('geoUseLocal').value" /><span class="ml-2">{{ this.getDiagDataCfg('geoUseLocal').label }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputNumber id="geoLongitudeLocal" ref="geoLongitudeLocal" :min="-180" :max="180"
                                            v-model="this.getDiagDataCfg('geoLongitudeLocal').value"
                                            :suffix="' ' + this.getDiagDataCfg('geoLongitudeLocal').unit"
                                            :minFractionDigits="6" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="geoLongitudeLocal" class="mr-2">{{ this.getDiagDataCfg('geoLongitudeLocal').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputNumber id="geoLatitudeLocal" ref="geoLatitudeLocal" :min="-90" :max="90"
                                            v-model="this.getDiagDataCfg('geoLatitudeLocal').value"
                                            :suffix="' ' + this.getDiagDataCfg('geoLatitudeLocal').unit"
                                            :minFractionDigits="6" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="geoLatitudeLocal" class="mr-2">{{ this.getDiagDataCfg('geoLatitudeLocal').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputNumber id="geoHeightLocal" ref="geoHeightLocal" :min="0" :max="9000"
                                            v-model="this.getDiagDataCfg('geoHeightLocal').value"
                                            :suffix="' ' + this.getDiagDataCfg('geoHeightLocal').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="geoHeightLocal" class="mr-2">{{ this.getDiagDataCfg('geoHeightLocal').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleCfg" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('cfg')" />
                    </div>
                </div>
            </template>
        </Dialog>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.key2">
                <span>🔴 {{ element.value }}</span>
            </div>
        </OverlayPanel>
        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="alarmStatus > 0" class="alarm-overlay">
            <div class="alarmticker">
                <span>{{ alarmTicker }}</span>
            </div>
        </div>       
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div :key="alarmStatus" v-show="true" class="hidden md:inline-flex social-icon" v-badge="alarmQueue" @click="toogleAlarmOverlay">
                        <i :class="`${icon} fs-xxlarge icon-md`"></i>
                    </div>
                    <div class="flex flex-column ml-3">
                        <div v-show="true" class="social-icon">
                            <i :class="`${iconSunrise}`" v-tooltip="getModuleValue('Sonne_Aufgang_UDINT').label"></i>
                        </div>
                        <span class="text-sm fg-gray">{{ getModuleValue('Sonne_Aufgang_UDINT').value }}</span>
                    </div>
                    <div class="flex flex-column ml-3">
                        <div v-show="true" class="social-icon">
                            <i :class="`${iconDay} text-6xl`" v-tooltip="getModuleValue('Tag').label"></i>
                        </div>
                        <span class="text-sm fg-gray">{{ textDay }}</span>
                    </div>
                    <div class="flex flex-column ml-3">
                        <div v-show="true" class="social-icon">
                            <i :class="`${iconSunset}`" v-tooltip="getModuleValue('Sonne_Untergang_UDINT').label"></i>
                        </div>
                        <span class="text-sm fg-gray">{{ getModuleValue('Sonne_Untergang_UDINT').value }}</span>
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('LOCAL_UDINT')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="getModuleValue('Sonne_Aufgang_UDINT')" :value2="getModuleValue('Sonne_Untergang_UDINT')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('Dauer_Tag')" :value2="getModuleValue('Dauer_Nacht')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('Geo_Longitude')" :value2="getModuleValue('Geo_Latitude')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('Geo_Height')" :value2="getModuleValue('UTC_UDINT')"></Widget_rowValues2>
                    </ul>
                </div>

                <div class="right flex flex-column">
                    <div class="flex flex-wrap mt-2" v-if="getGeoSphere">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <textbox-enat :title="`GeoSphere Standort`" :value="weatherWarningsLocation" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
                            <ScrollPanel class="mt-2" style="width: 100%; height: 140px">
                            <Timeline :value="weatherWarningsList" class="w-full md:w-80">
                                <template #opposite="slotProps">
                                    <small class="text-surface-500 dark:text-surface-400">{{slotProps.item.properties.begin}} - {{slotProps.item.properties.end}}</small>
                                </template>
                                <template #content="slotProps">
                                    <small class="text-surface-500 dark:text-surface-400">{{ slotProps.item.properties.text }}</small>
                                </template>
                            </Timeline>
                        </ScrollPanel>
                            <!-- <div v-for="(warning, index) in this.weatherWarningsList" :key="index">
                                <span :class="'flex justify-content-center card mb-1 fg-lightYellow p-1 text-sm bg-darkBlue'" v-tooltip="`${warning.properties.begin} - ${warning.properties.end}`"><i class="fi fi-rr-exclamation icon-inline fs-large fg-gray mr-1"></i>{{ warning.properties.text }}</span>
                            </div> -->
                            <!-- <span :class="'flex justify-content-center card m-0 fg-lightYellow p-1 text-sm bg-darkBlue'" v-tooltip="getModuleValue('DateTimeStr').label"><i class="fi fi-rr-pulse icon-inline fs-large fg-gray mr-1"></i>{{ getModuleValue('DateTimeStr').value }}</span> -->
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat :title="getOneWeekday(getModuleValue('Weekday').value)" :value="getModuleValue('TagesphaseStr').value" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
            <div class="flex justify-content-center align-items-center p-0 flex-wrap mt-2">
                <div class="flex-1 align-items-center justify-content-center mt-1 mb-2" v-if="isMaster">
                    <button-enat title="Allgemeine Einstellungen" subtitle="Alarme, Meldungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                       @click="toggleOP($event, 'cfg')" >
                    </button-enat>
                </div>
            </div>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import { mapGetters, mapActions } from 'vuex';
// import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
    name: 'widgetGeoDateTime',
    setup() { },
    components: {
        Widget_rowValues2,
        // dialogChartSingle,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
    },  
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            getSynoptoCode: 'types/getSynoptoCode',
            getWeekdays: 'types/getWeekdays',
            getOneWeekday: 'types/getOneWeekday',
            isMaster: 'auth/isMaster',
            getWeatherWarnings: 'getWeatherWarnings',
            getGeoSphere: 'socket/getGeoSphere'
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else {
                return '???';
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    activeAlarms.push(this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`));
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        valueListClass: function () {
            return "flex justify-content-center person-tag fg-lightGray bg-olive p-1 text-sm";
        },
        textDay: function () {
            if (this.getModuleValue('Tag').value) return `Tag`;
            else return `Nacht`;
        },
        iconDay: function () {
            if (this.getModuleValue('Tag').value) return `${this.getModuleValue('Tag').icon} fg-yellow `;
            else return `fi-rr-moon-stars fg-lightGray `;
        },
        iconSunrise: function () {
            return `${this.getModuleValue('Sonne_Aufgang_UDINT').icon} fg-lightBlue text-6xl`;
        },
        iconSunset: function () {
            return `${this.getModuleValue('Sonne_Untergang_UDINT').icon} fg-darkAmber text-6xl`;
        },
        weatherWarningsLocation: function () {
            return `${this.getWeatherWarnings.location}`;
        },
        weatherWarningsList: function () {
            if (Array.isArray(this.getWeatherWarnings.warnings) && this.getWeatherWarnings.warnings.length > 0) {
                return this.getWeatherWarnings.warnings;
            } else {
                return [{
                    text: 'keine aktiven Wetterwarnungen vorhanden'
                }];
            }
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            showChartRain: false,
            showChartStorm: false,
            showChartFrost: false,
            showChartFog: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            saveWait: false,
            dialogLoading: false,
            showDiagCfg: false,
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'cfg');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    // const location = {
                    //     lon: '16.291',
                    //     lat: '48.11',
                    //     lang: 'de'
                    // }
                    if (this.getGeoSphere) {
                        const location = {
                            lon: this.getModuleValue('Geo_Longitude').value,
                            lat: this.getModuleValue('Geo_Latitude').value,
                            lang: 'de'
                        }
                        this.loadWeatherWarnings(location);
                    }
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }

    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
            loadWeatherWarnings: 'loadWeatherWarnings'
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        resetCurrentGrp() {
            this.currentGrp = {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfg = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        returnChild(childStr) {
            return helpers.returnChild(this.nodeEntry, childStr);
        },
        returnParameter(node, key, parameter) {
            const path = helpers.findPath(node, key, parameter);
            let obj = helpers.getObjectFromPath(node, path);
            return obj;
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'cfg') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagCfg = true;
                }); 
            }
        },
        diagToggleCfg() {
            if (this.showDiagCfg) {
                this.showDiagCfg = false;
            } else {
                this.showDiagCfg = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'cfg') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    const flatHzg = this.nodeDataCfg.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataCfgOld.flatMap(innerArr => innerArr);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }

                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'cfg') this.showDiagCfg = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    this.dialogLoading = false;
                    if (diag === 'cfg') this.showDiagCfg = false;
                    this.hideOP();
                }
            }
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            // console.log(ioData);
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        else if (value === 'rain') this.showChartRain = true;
        else if (value === 'storm') this.showChartStorm = true;
        else if (value === 'frost') this.showChartFrost = true;
        else if (value === 'fog') this.showChartFog = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}
.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}
.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
    z-index: 999;
}
.card:hover .menu-overlay {
    opacity: 1;
}
.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}
.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}
</style>